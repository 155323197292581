import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div className="d-flex justify-content-end">
      <ul className="mainmenu">
        {/* <li>
          <Link to="/">About Us</Link>
        </li> */}
        {/* <li>
          <Link to="/contact-us">Contact Us</Link>
        </li> */}
      </ul>
    </div>
  );
};
export default Nav;
